@import "./assets/styles/itb-theme.scss";

.header-icon {

  color: #3f6fb5;

}

.mat-option-text,
.mat-button-toggle-button,
.mat-radio-label-content,
.mat-list-text {
  text-transform: capitalize;
  line-height: 1.25em;
}

.mat-button-toggle-checked {
  background-color: #3f51b5;
  color: #FFFFFF !important;
}

button:focus {
  outline: none;
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover {
  background: none;
}

.mat-horizontal-stepper-header .mat-step-icon, .mat-horizontal-stepper-header .mat-step-icon-not-touched {
  margin-right: -50px !important;
}

.reverse-checkbox .mat-list .mat-list-item .mat-list-item-content-reverse,
.reverse-checkbox .mat-list .mat-list-option .mat-list-item-content-reverse,
.reverse-checkbox .mat-nav-list .mat-list-item .mat-list-item-content-reverse,
.reverse-checkbox .mat-nav-list .mat-list-option .mat-list-item-content-reverse,
.reverse-checkbox .mat-selection-list .mat-list-item .mat-list-item-content-reverse,
.reverse-checkbox .mat-selection-list .mat-list-option .mat-list-item-content-reverse {
  flex-direction: row;
  padding: 0 8px;
}


.reverse-checkbox .mat-list .mat-list-item.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-list .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-list .mat-list-option.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-list .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-nav-list .mat-list-item.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-nav-list .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-nav-list .mat-list-option.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-nav-list .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-selection-list .mat-list-item.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-selection-list .mat-list-item.mat-list-option .mat-list-item-content-reverse .mat-list-text, .mat-selection-list
.reverse-checkbox .mat-list-option.mat-list-item-with-avatar .mat-list-item-content-reverse .mat-list-text,
.reverse-checkbox .mat-selection-list .mat-list-option.mat-list-option .mat-list-item-content-reverse .mat-list-text {
  padding-left: 8px;
}


/* .mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate,
.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate {
  background: #3f51b5 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background: #3f51b5 !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5 !important;
} */

.mat-calendar-body-available > .mat-calendar-body-cell-content {
  border: solid thin rgba(50,205,50,.7) !important;
  background-color: rgba(50, 205, 50, 0.1) !important;
}

.mat-calendar-body-disabled .mat-calendar-body-cell-content {
  background-color: rgba(255,255,255,1) !important;
}

.modal {
  background: rgba(0,0,0,.088);
}

.modal .modal-dialog {
  margin: 10vh auto;
  width: 80vw;
  max-width: none !important;
}

.mat-list .mat-list-option, .mat-nav-list .mat-list-option, .mat-selection-list .mat-list-option {
  font-size: 1rem;
}

@media (max-width: 576px) {

  html {
    font-size: 14px;
  }

  .modal .modal-dialog {
    margin: 0 !important;
    width: 100%;
    height:100%;
    max-width: none !important;
  }

}

@media (min-width: 1200px) {
  .modal .modal-dialog {
    margin: 12vh auto;
    width: 60vw;
  }
}

@media (min-width: 1500px) {
  .modal .modal-dialog {
    margin: 15vh auto;
    width: 50vw;
  }
}

.modal .modal-dialog .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
  overflow: hidden;
  overflow-y: hidden;
  overflow-y: scroll;
  padding-bottom: calc(30% / 7 - 16px);
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #6c757d;
}

/* .mat-pseudo-checkbox-checked::after {
  top: 3px !important;
  left: 1px !important;
  width: 14px !important;
  height: 7px !important;
} */

.mat-mdc-selection-list {
  --mdc-list-list-item-label-text-line-height: 16px;

  .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line {
    min-height: 56px;
    height: auto;
    padding: 4px 16px 4px 0;
  }

  .mdc-list-item__primary-text {
    white-space: normal;
  }

  .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
    margin-left: 8px;
    margin-right: 8px;
  }
}

label {
  margin-bottom: 0;
}



/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
